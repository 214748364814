<template>
	<edit-template class="page-wrapper white">

    <div class="page-info psychology-page form-padding">
      <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140rem" label-position="left">
        <div class="form-item-section-title">咨询信息</div>
        <el-form-item label="咨询主题" prop="theme_id">
          <el-select v-model="formData.theme_id" placeholder="请选咨询主题" @change="handleChange">
            <el-option v-for="item in themeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生姓名" prop="student_id">
          <el-select v-model="formData.student_id" filterable="" remote="" reserve-keyword="" placeholder="请输入学生关键字" @change="handleIdChange" :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in studentsList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="咨询开始时间" prop="start_at">
          <el-date-picker v-model="formData.start_at" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" type="datetime" placeholder="选择开始时间" default-time="12:00:00"></el-date-picker>
        </el-form-item>
        <el-form-item label="咨询结束时间" prop="end_at">
          <el-date-picker v-model="formData.end_at" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" type="datetime" placeholder="选择结束时间" default-time="12:00:00"></el-date-picker>
        </el-form-item>
        <el-form-item label="咨询地点" prop="record_chamber_id">
          <el-select v-model="formData.record_chamber_id" placeholder="请选择咨询地点">
            <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="测评分数" v-if="formData.theme_id == 12 &amp;&amp; formData.scl !== ''">
          <el-button disabled="" type="primary" plain="">SCL-90的测评结果：{{ formData.scl }}</el-button>
        </el-form-item>
        <div class="form-item-section-title">咨询总结</div>
        <!-- 测评后心理评估 -->
        <template v-if="formData.theme_id == 11">
          <div class="form-item-section">第一部分：具体事件、实时心情</div>
          <div class="form-item-section-title tips">1.最近两周的心情怎么样？</div>
          <el-form-item prop="evaluation1" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation1" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>学生的认知和情感</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">2.如果有烦恼，最烦恼的事情是什么？程度如何？已经持续多久了？是否已经严重影响了学习、生活？</div>
          <el-form-item prop="evaluation2" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation2" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>他认为事情：</div>
                <div>1不严重；2一般严重；3有点严重；4比较严重；5很严重</div>
                <div>情绪情感：</div>
                <div>1没烦恼；2一般烦恼；3有点烦恼；4比较烦恼；5烦恼、无法自拔</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section">第二部分：一般状态</div>
          <div class="form-item-section-title tips">1.觉得生活总体来说是怎样的？未来是否有希望？有些什么打算、理想？</div>
          <el-form-item prop="evaluation3" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation3" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>希望感：</div>
                <div>1充满希望；2有希望；3一般；4没希望；5令人绝望</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">2.最近一个月，有疲劳感吗？有些什么表现？</div>
          <el-form-item prop="evaluation4" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation4" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>疲劳感：</div>
                <div>1没有 ；2有时有点；3一般；4一半以上；5总是、非常</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">3.最近一个月，睡眠怎么样？</div>
          <el-form-item prop="evaluation5" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation5" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>睡眠：</div>
                <div>1 很好；2 较好；3一般；4 不太好；5很差</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">4.最近一个月，学习、作业或者生活方面的压力如何？</div>
          <el-form-item prop="evaluation6" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation6" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>学生的压力：</div>
                <div>1小、很轻松；2有点；3一般；4较大；5大、受不了</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section">第三部分：感知与应对方式</div>
          <div class="form-item-section-title tips">1.人们往往会议论别人，也会被人议论，当受到别人的评判时，心里会怎么想？是否会很不安？能否谈一谈想到的这个事情及当时的感受？</div>
          <el-form-item prop="evaluation7" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation7" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>对事情的敏感性：</div>
                <div>1不敏感；2有点敏感；3一般；4比较敏感；5非常敏感</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">
            2.人总有不顺心的时候。不如意、郁闷的时候，会做些什么？<br>曾有过以下行为吗？想要大哭、大喊大叫、砸东西、经常坐立不安、想报复/伤害别人、离家出走、觉得死了算了
          </div>
          <el-form-item prop="evaluation8" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation8" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>冲动性的应对方式：</div>
                <div>1不冲动；2有点冲动；3一般；4比较冲动；5非常冲动</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">3.在电视、网络上看到自杀事件时，认为可能发生了什么？</div>
          <el-form-item prop="evaluation9" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation9" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>对自杀的态度：</div>
                <div>1排斥；2有点排斥；3矛盾；4有点接受；5接受</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">
            4.曾经想到过自杀这件事吗？<br>1从来没有；2偶尔会出现短暂的自杀想法；3有时会出现较长时间的自杀想法；4经常会出现持续的自杀想法；5这个念头整天萦绕在脑中
          </div>
          <el-form-item prop="evaluation10" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation10" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>自杀意念：</div>
                <div>1从来没有；2偶尔有；3有时有；4经常有；5一直都有</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">5.曾经想过结束自己生命的方法吗？<br>1没想过；2想过怎样自杀比较好，但没制定出具体细节；3已制订具体计划；4我曾经尝试过自杀的行为</div>
          <el-form-item prop="evaluation11" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation11" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>自杀计划与行为程度：</div>
                <div>1没有；2 弱；3 较强；4 强</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section">第四部分：支持系统</div>
          <div class="form-item-section-title tips">1.家里的氛围一般是怎么样的？如不舒服，能否谈一谈具体情况？<br>1温暖 2比较温暖3一般4不太舒服5很不舒服</div>
          <el-form-item prop="evaluation12" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation12" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>【家庭支持】是否充足：</div>
                <div>1支持充足；2有基本支持；3支持很差</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">2.有好朋友吗？这些朋友是否亲密、要好？<br>1没有朋友2有朋友但是不太亲密3有几个亲密、要好的朋友</div>
          <el-form-item prop="evaluation13" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation13" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>【朋友支持】是否充足：</div>
                <div>1支持充足；2有基本支持；3支持很差</div>
              </div>
            </div>
          </el-form-item>
          <div class="form-item-section-title tips">3.遇到难以解决的困难时，会主动寻求帮助吗？能否详细举例？</div>
          <el-form-item prop="evaluation14" label-width="0">
            <div class="form-item-section-content">
              <div class="flex-1">
                <el-input type="textarea" v-model="formData.evaluation14" maxlength="300" show-word-limit="" :autosize="autosize"></el-input>
              </div>
              <div class="form-item-section-tips">
                <div>初步判断：</div>
                <div>有困难时，主观【感受到的人际支持】：</div>
                <div>1支持充足；2有基本支持；3支持很差</div>
              </div>
            </div>
          </el-form-item>
        </template>
        <!-- 首次咨询 -->
        <template v-if="formData.theme_id == 12">
          <div class="form-item-top-title tips">对来访者的第一印象</div>
          <el-form-item prop="first1" label-width="0">
            <el-input type="textarea" v-model="formData.first1" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">当前问题及来访者已有的应对方式</div>
          <el-form-item prop="first2" label-width="0">
            <el-input type="textarea" v-model="formData.first2" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">求助过程</div>
          <el-form-item prop="first3" label-width="0">
            <el-input type="textarea" v-model="formData.first3" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">家庭关系</div>
          <el-form-item prop="first4" label-width="0">
            <el-input type="textarea" v-model="formData.first4" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">学业表现</div>
          <el-form-item prop="first5" label-width="0">
            <el-input type="textarea" v-model="formData.first5" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <el-form-item prop="first6" label="是否存在心理危机">
            <el-radio-group v-model="formData.first6">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">一类</el-radio>
              <el-radio :label="2">二类</el-radio>
              <el-radio :label="3">三类</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <!-- 危机干预咨询 -->
        <template v-if="formData.theme_id == 10 || (formData.theme_id == 12 &amp;&amp; [2, 3].includes(formData.first6))">
          <div class="form-item-top-title tips">危机表现</div>
          <el-form-item prop="crisis1" label-width="0">
            <el-input type="textarea" v-model="formData.crisis1" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">个人经历</div>
          <el-form-item prop="crisis2" label-width="0">
            <el-input type="textarea" v-model="formData.crisis2" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">危机风险性因素</div>
          <el-form-item prop="crisis3" label-width="0">
            <el-input type="textarea" v-model="formData.crisis3" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">危机保护性因素</div>
          <el-form-item prop="crisis4" label-width="0">
            <el-input type="textarea" v-model="formData.crisis4" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">干预措施</div>
          <el-form-item prop="crisis5" label-width="0">
            <el-input type="textarea" v-model="formData.crisis5" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">咨询过程</div>
          <el-form-item prop="crisis6" label-width="0">
            <el-input type="textarea" v-model="formData.crisis6" maxlength="800" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">转介追踪</div>
          <el-form-item prop="crisis7" label-width="0">
            <el-input type="textarea" v-model="formData.crisis7" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
        </template>
        <template v-if="![10, 11, 12].includes(Number(formData.theme_id))">
          <div class="form-item-top-title tips">咨询目标</div>
          <el-form-item prop="other1" label-width="0">
            <el-input type="textarea" v-model="formData.other1" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">学生自述</div>
          <el-form-item prop="other2" label-width="0">
            <el-input type="textarea" v-model="formData.other2" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
          <div class="form-item-top-title tips">咨询方案</div>
          <el-form-item prop="other3" label-width="0">
            <el-input type="textarea" v-model="formData.other3" maxlength="500" show-word-limit="" :autosize="autosize"></el-input>
          </el-form-item>
        </template>
        <!-- 上传咨询记录 -->
        <div class="form-item-top-title">上传咨询记录（选填）</div>
        <el-form-item label-width="0">
          <uploads :list="fileList" @change="handleFileChange"></uploads>
        </el-form-item>

        <el-form-item label="是否安排后续咨询" prop="is_next">
          <el-radio-group v-model="formData.is_next">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="formData.is_next === 1">
          <el-form-item label="下次咨询时间" prop="next_subscribe_start_time">
            <el-button type="primary" plain="" size="small" @click="handleShowDialog">
              {{ formData.next_subscribe_start_time ? formData.next_subscribe_start_time + ' ~ ' + formData.next_subscribe_end_time : '选择时间' }}
            </el-button>
          </el-form-item>
          <el-form-item label="下次咨询地点" prop="chamber_id">
            <el-select v-model="formData.chamber_id" placeholder="请选择咨询地点">
              <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-form>
      <TimeSelect :visible.sync="isShowDialog" :school_id="school_id" @submit="handleDateSubmit"></TimeSelect>
    </div>
    <template slot="footer">
      <el-button type="primary" @click="submitForm('ruleForm')">提交记录</el-button>
      <el-button debounce="" type="success" @click="submitForm('')">保存草稿</el-button>
      <el-button @click="resetForm('ruleForm')">取消</el-button>
    </template>

</edit-template>
</template>

<script>
import TimeSelect from '../components/TimeSelect.vue'
import Uploads from '@/components/common/Uploads.vue'
import { getApi2, postApi2 } from '@/api'
export default {
	_config:{"route":{"path":"add","meta":{"title":"补录"}}},
  name: 'Add',
  components: { TimeSelect, Uploads },
  data() {
    return {
      isShowDialog: false,
      autosize: {
        minRows: 5,
        maxRows: 8
      },
      formData: {
        scl: '',
        theme_id: '',
        record_chamber_id: '',
        student_id: '',
        start_at: '',
        end_at: '',
        content: '',
        is_next: 0,
        next_subscribe_start_time: '',
        next_subscribe_end_time: '',
        chamber_id: '',
        other1: '',
        other2: '',
        other3: '',
        crisis1: '',
        crisis2: '',
        crisis3: '',
        crisis4: '',
        crisis5: '',
        crisis6: '',
        crisis7: '',
        evaluation1: '',
        evaluation2: '',
        evaluation3: '',
        evaluation4: '',
        evaluation5: '',
        evaluation6: '',
        evaluation7: '',
        evaluation8: '',
        evaluation9: '',
        evaluation10: '',
        evaluation11: '',
        evaluation12: '',
        evaluation13: '',
        evaluation14: '',
        evaluation15: '',
        evaluation16: '',
        first1: '',
        first2: '',
        first3: '',
        first4: '',
        first5: '',
        first6: ''
      },
      fileList: [],
      rules: {
        student_id: [{ required: true, message: '请选择学生' }],
        start_at: [{ required: true, message: '请选咨询开始时间', trigger: 'change' }],
        theme_id: [{ required: true, message: '请选择咨询主题' }],
        end_at: [{ required: true, message: '请选择咨询结束时间', trigger: 'change' }],
        is_next: [{ required: true, message: '请选择是否安排后续咨询' }],
        next_subscribe_start_time: [{ required: true, message: '下次咨询时间', trigger: 'change' }],
        record_chamber_id: [{ required: true, message: '请选择咨询地点', trigger: 'change' }],
        chamber_id: [{ required: true, message: '请选择下次咨询地点', trigger: 'change' }],
        other1: [{ required: true, message: '请输入咨询目标', trigger: 'change' }],
        other2: [{ required: true, message: '请输入学生自述', trigger: 'change' }],
        other3: [{ required: true, message: '请输入咨询方案', trigger: 'change' }],
        crisis1: [{ required: true, message: '请输入危机表现', trigger: 'change' }],
        crisis2: [{ required: true, message: '请输入个人经历', trigger: 'change' }],
        crisis3: [{ required: true, message: '请输入危机风险性因素', trigger: 'change' }],
        crisis4: [{ required: true, message: '请输入危机保护性因素', trigger: 'change' }],
        crisis5: [{ required: true, message: '请输入干预措施', trigger: 'change' }],
        crisis6: [{ required: true, message: '请输入咨询过程', trigger: 'change' }],
        crisis7: [{ required: true, message: '请输入转介追踪', trigger: 'change' }],
        evaluation1: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation2: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation3: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation4: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation5: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation6: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation7: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation8: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation9: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation10: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation11: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation12: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation13: [{ required: true, message: '请输入', trigger: 'change' }],
        evaluation14: [{ required: true, message: '请输入', trigger: 'change' }],
        first1: [{ required: true, message: '请输入对来访者的第一印象', trigger: 'change' }],
        first2: [{ required: true, message: '请输入当前问题及来访者已有的应对方式', trigger: 'change' }],
        first3: [{ required: true, message: '请输入求助过程', trigger: 'change' }],
        first4: [{ required: true, message: '请输入家庭关系', trigger: 'change' }],
        first5: [{ required: true, message: '请输入学业表现', trigger: 'change' }],
        first6: [{ required: true, message: '请选择是否存在心理危机', trigger: 'change' }]
      },
      studentsList: [],
      loading: false,
      themeList: [],
      roomList: [],
      school_id: 0,
      id: 0
    }
  },
  watch: {
    school_id(val) {
      if (val) {
        this.formData.record_chamber_id = ''
        this.formData.chamber_id = ''
        this.getRoom()
      }
    },
    'formData.student_id'(val) {
      this.getSCL()
    }
  },
  created() {
    this.getTheme()
    this.id = +this.$route.query.id
    if(this.id) {
      this.getDetail()
    }
  },
  methods: {
    getTheme() {
      getApi2('/evaluation/sum-up/get-param').then(res => {
        const { theme } = res
        this.themeList = theme.map(item => ({ label: item.sub_name, value: item.id }))
      })
    },
    getRoom() {
      getApi2('/evaluation/counseling-setting/chamber-enumeration', { school_id: this.school_id }).then(res => {
        this.roomList = res.map(item => ({ label: `${item.chamber_name}（${item.building_name}${item.chamber_no}）`, value: item.id }))
      })
    },
    getSCL() {
      getApi2('/evaluation/psych/get-scl', { student_id: this.formData.student_id }).then(res => {
        const { score } = res
        this.formData.scl = score ?? 0
      })
    },
    getDetail() {
      getApi2('/evaluation/sum-up/details', { id: this.id }).then(res => {
        const { student, counsel } = res

        if (counsel) {
          const { theme_id, chamber_id, next_chamber_id, student_id, school_id, summary, start_at, end_at, next_subscribe_end_at, next_subscribe_start_at, is_next, imgs } = counsel
          if(school_id) this.school_id = school_id
          if(theme_id) this.formData.theme_id = theme_id
          if(student_id) this.formData.student_id = student_id
          if(start_at) this.formData.start_at = start_at
          if(end_at) this.formData.end_at = end_at

          if(is_next !== '') this.formData.is_next = is_next
          if(next_subscribe_end_at) this.formData.next_subscribe_end_time = next_subscribe_end_at
          if(next_subscribe_start_at) this.formData.next_subscribe_start_time = next_subscribe_start_at

          if(imgs) {
            const arr = imgs.split(',')
            this.fileList = arr.map(src => ({src, status: 'uploaded'}))
          }
          setTimeout(() => {
            if(next_chamber_id) this.formData.chamber_id = next_chamber_id
            if(chamber_id) this.formData.record_chamber_id = chamber_id
          }, 100)

          if (student && student.student_name) {
            this.getSearch(student.student_name)
          }
          if(theme_id === 10) {
            if(summary[0]) this.formData.crisis1 = summary[0]
            if(summary[1]) this.formData.crisis2 = summary[1]
            if(summary[2]) this.formData.crisis3 = summary[2]
            if(summary[3]) this.formData.crisis4 = summary[3]
            if(summary[4]) this.formData.crisis5 = summary[4]
            if(summary[5]) this.formData.crisis6 = summary[5]
            if(summary[6]) this.formData.crisis7 = summary[6]
            return
          }
          if(theme_id === 11) {
            if(summary[0]) this.formData.evaluation1 = summary[0]
            if(summary[1]) this.formData.evaluation2 = summary[1]
            if(summary[2]) this.formData.evaluation3 = summary[2]
            if(summary[3]) this.formData.evaluation4 = summary[3]
            if(summary[4]) this.formData.evaluation5 = summary[4]
            if(summary[5]) this.formData.evaluation6 = summary[5]
            if(summary[6]) this.formData.evaluation7 = summary[6]
            if(summary[7]) this.formData.evaluation8 = summary[7]
            if(summary[8]) this.formData.evaluation9 = summary[8]
            if(summary[9]) this.formData.evaluation10 = summary[9]
            if(summary[10]) this.formData.evaluation11 = summary[10]
            if(summary[11]) this.formData.evaluation12 = summary[11]
            if(summary[12]) this.formData.evaluation13 = summary[12]
            if(summary[13]) this.formData.evaluation14 = summary[13]
            if(summary[14]) this.formData.evaluation15 = summary[14]
            if(summary[15]) this.formData.evaluation16 = summary[15]
            return
          }
          if(theme_id === 12) {
            if(summary[0]) this.formData.first1 = summary[0]
            if(summary[1]) this.formData.first2 = summary[1]
            if(summary[2]) this.formData.first3 = summary[2]
            if(summary[3]) this.formData.first4 = summary[3]
            if(summary[4]) this.formData.first5 = summary[4]
            if(summary[5] !== '' && summary[5] !== null) this.formData.first6 = summary[5]
            if(summary[5] > 1) {
              if(summary[6]) this.formData.crisis1 = summary[6]
              if(summary[7]) this.formData.crisis2 = summary[7]
              if(summary[8]) this.formData.crisis3 = summary[8]
              if(summary[9]) this.formData.crisis4 = summary[9]
              if(summary[10]) this.formData.crisis5 = summary[10]
              if(summary[11]) this.formData.crisis6 = summary[11]
              if(summary[12]) this.formData.crisis7 = summary[12]
            }
            return
          }
          if(summary[0]) this.formData.other1 = summary[0]
          if(summary[1]) this.formData.other2 = summary[1]
          if(summary[2]) this.formData.other3 = summary[2]
        }
      })
    },
    getSearch(keyword) {
      this.loading = true
      getApi2('/evaluation/appointment/student', { keyword })
        .then(res => {
          this.studentsList = res.map(item => ({
            value: item.id,
            label: `${item.student_name}(${item.student_no})`,
            school_id: item.school_id
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleIdChange(val) {
      const obj = this.studentsList.find(item => item.value === val)
      this.school_id = obj.school_id
    },
    handleChange() {
      this.$refs['ruleForm'].clearValidate()
    },
    handleDateSubmit(val) {
      this.formData.next_subscribe_start_time = val.startTime
      this.formData.next_subscribe_end_time = val.endTime
    },
    handleShowDialog() {
      this.isShowDialog = true
    },
    remoteMethod(query) {
      if (query !== '') {
        this.getSearch(query)
      } else {
        this.studentsList = []
      }
    },
    handleFileChange(arr) {
      this.fileList = arr
    },
    submitForm(formName) {
      if(!formName) return this.requestHttp(1)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.requestHttp()
        }
      })
    },
    requestHttp(type) {
      let params = {
        id: this.$route.query.id,
        theme_id: this.formData.theme_id,
        is_next: this.formData.is_next,
        record_chamber_id: this.formData.record_chamber_id,
        student_id: this.formData.student_id,
        start_at: this.formData.start_at,
        end_at: this.formData.end_at
      }
      if (this.formData.theme_id == 10) {
        // 危机干预咨询
        params.content = [this.formData.crisis1, this.formData.crisis2, this.formData.crisis3, this.formData.crisis4, this.formData.crisis5, this.formData.crisis6, this.formData.crisis7]
      } else if (this.formData.theme_id == 11) {
        // 测评后心理评估
        params.content = [this.formData.evaluation1, this.formData.evaluation2, this.formData.evaluation3, this.formData.evaluation4, this.formData.evaluation5, this.formData.evaluation6, this.formData.evaluation7, this.formData.evaluation8, this.formData.evaluation9, this.formData.evaluation10, this.formData.evaluation11, this.formData.evaluation12, this.formData.evaluation13, this.formData.evaluation14]
      } else if (this.formData.theme_id == 12) {
        // 首次咨询
        let arr1 = [this.formData.first1, this.formData.first2, this.formData.first3, this.formData.first4, this.formData.first5, this.formData.first6]
        let arr2 = []
        if(this.formData.first6 > 1) {
          arr2 = [this.formData.crisis1, this.formData.crisis2, this.formData.crisis3, this.formData.crisis4, this.formData.crisis5, this.formData.crisis6, this.formData.crisis7]
        }
        params.scl = this.formData.scl
        params.content = [...arr1, ...arr2]
      } else {
        // 其他选项
        params.content = [this.formData.other1, this.formData.other2, this.formData.other3]
      }
      const files = this.fileList.map(item => ({ src: item.src || item?.response?.data?.file_addr, status: item.status }))
      const isOk = files.every(item => ['success', 'uploaded'].includes(item.status))
      if(!isOk) {
        return this.$message.warning('图片未上传成功，请重试！')
      }
      params.imgs = files.map(item => item.src).join(',')
      if (params.is_next) {
        params.next_subscribe_start_time = this.formData.next_subscribe_start_time
        params.next_subscribe_end_time = this.formData.next_subscribe_end_time
        params.chamber_id = this.formData.chamber_id
      }
      if(type) {
        params.draft = 1
      }
      if(this.id) {
        params.id = this.id
      }
      postApi2('/evaluation/sum-up/up-record', params).then(() => {
        this.$router.back()
        this.$message.success(`操作成功！`)
      })
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields()
      let that = this
      this.$confirm('确定取消补录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$router.back()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
